<template>
 <div class="community oepnX">
  <!--    <div class="communityBg">-->
  <!--      <div class="communityBg2"></div>-->
  <!--    </div>-->
  <van-tabs class="tabs oepnX" swipeable background="rgba(0,0,0,0)" @change="tabChange" line-width="22"
            line-height="3" title-inactive-color="#333333" title-active-color="#333333"
            v-model="tabActive">
   <van-tab title="关注" class="tabContent">
    <PullRefresh v-if="!gIsLoading" :loading="gLoading" :refreshing="gRefreshing" :finished="gFinished"
                 @onLoad="onLoad" @onRefresh="onRefresh" :isNoData="gIsNoData" :error="error"
                 :is-higeht-max="true"
                 :hasAdvPagination="true" className="VideoDetailsPage">
     <div class="focusContent">
      <!-- 轮播广告 -->
      <div class="advertising">
       <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="goAdv(item)">
         <ImgDecypt :src="item.cover"/>
        </van-swipe-item>
       </van-swipe>
      </div>

      <!-- 官方博主 -->
      <div class="authorityTopics" @touchmove.stop v-if="authorityBloggerList && authorityBloggerList.length > 0">
       <div class="topicsHead">
        <div class="topicsTitle">
         <span>官方博主</span>
        </div>
        <div class="more" @click="moreAuthorityBlogger">
         <!--                          <span>更多</span>-->
         <img src="@/assets/png/rightIcon.png">
        </div>
       </div>
       <div class="topicsContent">

        <div class="topicsItem" v-for="(item,index) in authorityBloggerList" :key="index"
             @click.stop="jumpUserHome(item)">
         <div class="portrait">
          <ImgDecypt class="avatarImg" :src="item.portrait" round></ImgDecypt>
          <svg-icon v-if="item.superUser > 0" icon-class="authIcon" class="authIcon"></svg-icon>
         </div>
         <div class="topicsInfo">
          <div class="topicsInfoTitle">
           <span>{{ item.name }}</span>
          </div>
          <div class="topicsInfoContent">
           <span>{{ item.desc }}</span>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="communityList">
       <CommunityList :communityList="gCommunityList"></CommunityList>
      </div>
     </div>
    </PullRefresh>
    <Loading v-else/>
   </van-tab>
   <van-tab title="发现" class="tabContent">
    <PullRefresh v-if="!rIsLoading" :loading="rLoading" :refreshing="rRefreshing" :finished="rFinished"
                 @onLoad="onLoad" @onRefresh="onRefresh" :isNoData="rIsNoData" :error="error"
                 :is-higeht-max="true"
                 :hasAdvPagination="true" className="VideoDetailsPage">
     <div class="makeContent">
      <!-- 轮播广告 -->
      <div class="advertising">
       <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="goAdv(item)">
         <ImgDecypt :src="item.cover"/>
        </van-swipe-item>
       </van-swipe>
      </div>

      <!-- 热门话题 -->
      <div class="hotTopics" @touchmove.stop v-if="hotspotWonderList && hotspotWonderList.length > 0">
       <div class="topicsHead">
        <div class="topicsTitle">
         <span>热门话题</span>
        </div>
        <div class="more" @click="moreTopic">
         <!--                  <span>更多</span>-->
         <img src="@/assets/png/rightIcon.png">
        </div>
       </div>
       <div class="topicsContent">

        <div class="topicsItem" v-for="(item,index) in hotspotWonderList" :key="index"
             @click="tagJump(item)">
         <div class="portrait">
          <ImgDecypt class="avatarImg" :src="item.coverImg"/>
         </div>
         <div class="topicsInfo">
          <div class="topicsInfoTitle">
           <span>{{ item.name }}</span>
          </div>
          <!--                    <div class="topicsInfoContent">-->
          <!--                      <span>{{ item.tagDesc }}</span>-->
          <!--                    </div>-->
         </div>
        </div>
       </div>
      </div>

      <!-- 帖子列表 -->
      <div class="communityList">
       <CommunityList :communityList="rCommunityList"></CommunityList>
      </div>
     </div>
    </PullRefresh>
    <Loading v-else/>
   </van-tab>
  </van-tabs>

  <div class="editorBtn" @click.stop="openRelease">
   <img src="@/assets/png/editorBtn.png">
  </div>

  <van-overlay :show="releaseShow" @click="releaseShow = false">
   <div class="releaseBtns" @click.stop>
    <div class="releaseBtnGroup">
     <div class="item" @click.stop="jumpImg">
      <img src="@/assets/png/imgIcon.png">
      <span>图片</span>
     </div>
     <div class="division"></div>
     <div class="item" @click.stop="jumpVideo">
      <img src="@/assets/png/videoIcon.png">
      <span>视频</span>
     </div>
     <!-- <div @click="releasePage">
       <span>图片</span>
   </div>
   <div class="division"></div>
   <div @click="releasePage">
       <span>视频</span>
   </div> -->
    </div>
   </div>
  </van-overlay>

  <!--      <div class="collectBtn" v-else @click="cancelCollectionWorks">-->
  <!--          <img src="@/assets/png/hasCollectBtn.png">-->
  <!--      </div>-->
 </div>

</template>
<script>
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import {
 queryCommunityList,
 queryFollowDynamicsList,
 queryHotspotWonderList, queryRecommendUserList
} from "@/api/community";

import {
 careOrcancle,
 zan,
 cancleZan
} from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
import {
 setSessionItem
} from "@/utils/longStorage";
import CommunityList from "@/components/community/CommunityList";
import {
 AdType,
 getAdItem,
 jumpAdv
} from "@/utils/getConfig";
import {
 mapGetters
} from "vuex";

export default {
 name: "Community",
 components: {
  PullRefresh,
  Loading,
  ImgDecypt,
  CommunityList
 },
 data() {
  return {
   gIsLoading: true,
   rIsLoading: true,
   gLoading: true,
   rLoading: true,
   gFinished: false,
   rFinished: false,
   gIsNoData: false,
   rIsNoData: false,
   error: false,
   gPageNumber: 1,
   rPageNumber: 1,
   gPageSize: 12,
   rPageSize: 12,
   gCommunityList: [],
   rCommunityList: [],
   tabActive: 1,
   commentsShow: false,
   gRefreshing: false,
   rRefreshing: false,
   hotspotWonderList: [],
   authorityBloggerList: [],
   releaseShow: false,
   bannerList: []
  }
 },
 computed: {
  ...mapGetters({
   userInfo: 'userInfo',
   isVip: 'isVip'
  }),
 },
 mounted() {
  if (this.tabActive == 1) {
   this.getCommunityList('refresh');
   this.getHotspotWonderList();
   this.getAuthorityBloggerList();
  } else {
   this.getFollowDynamicsList('refresh');
   this.getAuthorityBloggerList();
  }
  this.bannerList = getAdItem(AdType.COMMUNITY_POST);
 },
 methods: {
  // 跳转用户主页
  jumpUserHome(item) {
   this.$router.push({
    path: "/userHomePage",
    query: {
     uid: item.uid
    }
   })
  },
  onRefresh() {
   if (this.tabActive == 1) {
    this.rRefreshing = true;
    this.rPageNumber = 1;
    this.rFinished = false;
    this.rIsNoData = false;
    this.getCommunityList('refresh');
   } else {
    this.gRefreshing = true;
    this.gPageNumber = 1;
    this.gFinished = false;
    this.gIsNoData = false;
    this.getFollowDynamicsList('refresh');
   }
  },
  onLoad() {
   if (this.tabActive == 1) {
    this.rLoading = true;
    if (this.error) {
     this.error = false;
    } else {
     this.rPageNumber++
    }
    this.getCommunityList();
   } else {
    if (this.error) {
     this.error = false;
    } else {
     this.gPageNumber++
    }
    this.gLoading = true;
    this.getFollowDynamicsList();
   }
  },
  async getCommunityList(type) {
   /**
    * 帖子发现列表
    */
   let req = {
    pageNumber: this.rPageNumber,
    pageSize: this.rPageSize,
    tag: "最新",
    reqDate: new Date().toISOString()
   }
   let res = await this.$Api(queryCommunityList, req);

   this.rLoading = false;
   this.rRefreshing = false;
   this.rIsLoading = false;
   try {
    if (res && res.code == 200) {

     if (res.data.list) {
      if (type == "refresh") {
       this.rCommunityList = res.data.list;
      } else {
       this.rCommunityList = this.rCommunityList.concat(res.data.list);
      }
     } else {
      this.rCommunityList = [];
     }

     // if (this.rPageNumber === 1 && this.rCommunityList.length === 0) {
     //   this.rIsNoData = true;
     // }

     if (!res.data.hasNext || res.data.list.length < req.rPageSize) {
      this.rFinished = true;
     }
    } else {
     this.error = true;
    }
   } catch (error) {
    this.error = true;
    this.rLoading = false;
   }

  },
  async getFollowDynamicsList(type) {
   /**
    * 帖子关注列表
    */
   let req = {
    pageNumber: this.gPageNumber,
    pageSize: this.pageSize,
   }
   let res = await this.$Api(queryFollowDynamicsList, req);

   this.gLoading = false;
   this.gRefreshing = false;
   this.gIsLoading = false;

   if (res && res.code == 200) {

    if (res.data.list) {
     if (type == "refresh") {
      this.gCommunityList = res.data.list;
     } else {
      this.gCommunityList = this.gCommunityList.concat(res.data.list);
     }
    } else {
     this.gCommunityList = [];
    }

    // if (this.gPageNumber === 1 && this.gCommunityList.length === 0) {
    //   this.gIsNoData = true;
    // }

    if (res.data.list) {
     if (!res.data.hasNext || res.data.list.length < req.gPageSize) {
      this.gFinished = true;
     }
    } else {
     this.gFinished = true;
    }

   }

  },
  async tabChange(item) {
   if (this.tabActive == 1) {
    this.rIsLoading = true;
    await this.getCommunityList('refresh');
   } else {
    this.gIsLoading = true;
    await this.getFollowDynamicsList('refresh');
   }
  },
  async getHotspotWonderList() {
   /**
    * 获取官方博主列表
    */
   let req = {
    pageNumber: 1,
    pageSize: 50,
   }
   let res = await this.$Api(queryHotspotWonderList, req);

   if (res && res.code == 200) {
    this.hotspotWonderList = res.data.list;
   }

  },
  async getAuthorityBloggerList() {
   /**
    * 获取热门话题列表
    */
   let req = {
    pageNumber: 1,
    pageSize: 50,
   }
   let res = await this.$Api(queryRecommendUserList, req);
   if (res && res.code == 200) {
    this.authorityBloggerList = res.data.list;
   }

  },
  moreTopic() {
   /**
    * 更多话题（热门话题）
    */
   this.$router.push({
    path: "/hotTopic"
   })
  },
  moreAuthorityBlogger() {
   /**
    * 更多官方博主
    */
   this.$router.push({
    path: "/authorityBlogger"
   })
  },
  releasePage() {
   /**
    * 发布作品
    */

   this.releaseShow = false;

   this.$router.push({
    path: "/releasePage"
   })
  },
  goAdv(item) {
   jumpAdv(item);
  },
  tagJump(item) {
   /**
    * 话题详情
    */
   this.$router.push({
    path: "/topicDetails",
    query: {
     id: item.id
    }
   })
  },
  openRelease() {
   // console.log(123213)
   // if (this.userInfo.isVip) {
   this.releaseShow = true
   // } else {
   //     this.$bus.$emit("vipPopup", {
   //         // id: "",
   //         state: 1,
   //         // goldenNum: this.postInfo.coins,
   //         closeBtn: () => {
   //             this.$bus.$emit("closeVipPopup");
   //         }
   //     });
   // }
  },
  jumpImg() {
   this.$router.push("/publishImg")
   // if (this.userInfo.isVip) {
   //     this.$router.push("/publishImg")
   // } else {
   //     this.$bus.$emit("vipPopup", {
   //         // id: "",
   //         state: 1,
   //         // goldenNum: this.postInfo.coins,
   //         closeBtn: () => {
   //             this.$bus.$emit("closeVipPopup");
   //         }
   //     });
   // }
  },
  jumpVideo() {
   this.$router.push("/publishVideo")
   // if (this.userInfo.isVip) {
   //     this.$router.push("/publishVideo")
   // } else {
   //     this.$bus.$emit("vipPopup", {
   //         // id: "",
   //         state: 1,
   //         // goldenNum: this.postInfo.coins,
   //         closeBtn: () => {
   //             this.$bus.$emit("closeVipPopup");
   //         }
   //     });
   // }
  },
 }
};
</script>
<style lang="scss" scoped>
.community {

 .communityBg {
  width: 100%;
  height: 345px;
  position: absolute;
  background: linear-gradient(180deg, #A2DE8A 0%, rgba(162, 222, 138, 0.00) 100%);

  .communityBg2 {
   position: relative;
   width: 613.333px;
   height: 345px;
   background: url('../../assets/png/communityBg.png'), lightgray 50% / cover no-repeat;
   background-size: 100% 100%;
   filter: blur(2.5px);
   //mix-blend-mode: color-burn;
   mix-blend-mode: overlay;
  }
 }

 /deep/ .van-tabs__nav--line {
  width: 120px;
 }

 /deep/ .van-tabs__wrap {
  //background: rgb(27,22,76);
  //border-bottom: solid 1px rgb(230, 230, 230);
 }

 /deep/ .van-tabs--line .van-tabs__wrap {
  height: 51px;
 }

 /deep/ .van-tab--active {
  font-size: 20px !important;
  font-weight: bold;
 }

 /deep/ .van-tab {
  font-size: 14px;
 }

 /deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0);
 }

 /deep/ .van-tabs__line {
  bottom: 25px;
  background: transparent;
  background: url("../../assets/png/tabLine.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
 }

 .releaseBtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 51px;
  margin-right: 12px;

  .releaseBtnGroup {
   //width: 121px;
   //height: 41px;
   background: linear-gradient(180deg, #FFFCE0 0%, #FFF 100%);
   border-top-left-radius: 6px;
   border-bottom-left-radius: 6px;
   border-bottom-right-radius: 6px;
   padding: 10px 8px;

   display: flex;
   flex-direction: column;
   align-items: center;
   //justify-content: center;
   font-size: 15px;
   color: rgb(255, 255, 255);

   .item {
    display: flex;
    align-items: center;
    color: #FF8F4C;
    font-weight: bold;
    font-size: 14px;

    img {
     width: 24px;
     height: 24px;
     margin-right: 10px;
    }
   }

   .division {
    width: 84px;
    height: 1px;
    background: #FF8F4C;
    margin: 6px 0;
   }
  }
 }

 .advertising {
  padding: 0 12px;
  margin-top: 12px;

  .swipe {
   height: 146.25px;

   .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: rgb(43, 39, 72);
    border-radius: 8px;
   }

   /deep/ .van-swipe__indicators {
    left: auto;
    right: 0;
    align-items: center;
   }

   /deep/ .van-image__img {
    border-radius: 8px;
   }

   /deep/ .van-swipe__indicator {
    width: 6px;
    height: 6px;
    background: rgba(148, 214, 218, .45) !important;
   }

   /deep/ .van-swipe__indicator--active {
    width: 9px;
    height: 9px;
    background: #94D6DA !important;
   }
  }
 }

 .authorityTopics {
  //border-bottom: solid #73CD50 1px;
  padding: 18px 16px 18px 16px;

  .topicsHead {
   color: #212121;
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   align-items: center;

   .topicsTitle {
    font-weight: bold;
   }

   .more {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #33691E;
   }

   img {
    width: 12px;
    height: 12px;
    //margin-left: 6.1px;
   }
  }

  .topicsContent {
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
   margin-top: 12px;
   border-radius: 8px;
   //padding: 0 8px 14px 8px;
   background-size: 100% 100%;
   overscroll-behavior: contain;

   .topicsItem {
    //margin-top: 12px;
    margin-right: 18px;
    //width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-between;

    .portrait {
     width: 60px;
     height: 60px;
     position: relative;

     .authIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 15px;
      height: 15px;
     }
    }

    .topicsInfo {
     margin-left: 6px;
     font-size: 12px;
     //width: 90px;

     .topicsInfoTitle {
      color: #333;
      font-size: 10px;
      text-align: center;
      font-weight: 500;
      //font-weight: bold;
      margin-top: 6px;

      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
     }

     .topicsInfoContent {
      width: 60px;
      color: #666;
      font-size: 10px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      margin-top: 2px;
     }
    }
   }
  }
 }

 .hotTopics {
  //border-bottom: solid #73CD50 1px;
  padding: 18px 16px 18px 16px;

  .topicsHead {
   color: #212121;
   display: flex;
   justify-content: space-between;
   font-size: 16px;
   align-items: center;

   .topicsTitle {
    font-weight: bold;
   }

   .more {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #33691E;
   }

   img {
    width: 12px;
    height: 12px;
    //margin-left: 6.1px;
   }
  }

  .topicsContent {
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
   margin-top: 12px;
   border-radius: 8px;
   //padding: 0 8px 14px 8px;
   background-size: 100% 100%;
   overscroll-behavior: contain;

   .topicsItem {
    //margin-top: 12px;
    margin-right: 5px;
    //width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-between;

    .portrait {
     width: 60px;
     height: 60px;
     border-radius: 4px;
     //background: rgb(230, 230, 230);

     /deep/ .van-image__img {
      border-radius: 8px;
     }
    }

    .topicsInfo {
     margin-left: 6px;
     font-size: 12px;
     //width: 90px;

     .topicsInfoTitle {
      color: #999999;
      font-size: 10px;
      text-align: center;
      //font-weight: bold;
      margin-top: 4px;
     }

     .topicsInfoContent {
      color: #9E9E9E;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      margin-top: 4px;
     }
    }
   }
  }
 }

 .tabs {
  //border-bottom: solid 1px red;
  // /deep/ .van-tabs__wrap {
  //     display: flex;
  // }
  //   display: flex;

  .tabContent {
   height: calc(100vh - 51px - 86px);

   .makeContent {
    //padding: 0 12px;
    //height: calc(100vh - 51px - 86px);


   }
  }
 }

 .editorBtn {
  position: absolute;
  top: 13px;
  right: 12px;
  //right: 12px;
  //padding-top: 12px;
  //background: rgb(27,22,76);

  img {
   width: 24px;
   height: 24px;
  }
 }

 .communityList {
  //margin-top: 30px;


 }
}
</style>